import './App.css';
import NavBar from './components/Navbar';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useState, useEffect } from 'react';
import '@wfp/ui/src/globals/scss/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [lngUsed, setLngUsed]= useState("ar");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("ar");
    document.documentElement.dir = 'rtl';
    setLngUsed('ar');
  }, [i18n]);

  const lngs = {
    en: {nativeName: 'English' },
    ar: {nativeName: 'العربية'}
  }

  function changeLanguage (lng) {
    i18next.changeLanguage(lng);
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
    setLngUsed(lng)
  };
  
  return (
    <div className="App">
        <NavBar lngs={lngs}
                changeLanguage={changeLanguage}
                shopHeaderName={t('shopHeaderTitle')}
                mtoHeaderName={t('mtoHeaderTitle')}
                essnMtoHaderName={t('essnMtoHeaderTitle')}
                sitesHeaderName={t('sitesHeaderTitle')}
                pageName1={t('shopPage')} 
                pageName2={t('mtoPage')}
                pageName3={t('essnMtoPage')}
                pageName4={t('sitesPage')}
                placeHolderName1={t('placeHolder1')} 
                placeHolderName2={t('placeHolder2')}
                shopsStatment={t('shopsDisplayedStatment')} 
                mtosStatment={t('mtosDisplayedStatment')}
                essnMtosStatment={t('essnMtosDisplayedStatment')} 
                sitesStatement={t('sitesDisplayedStatment')}
                alertMessage={t('filteringMessage')}
                usedLanguage={lngUsed}
                dataLoad={t('dataLoad')}
                loading={t('loading')}
                location={t('location')}
                bannerMessage={t('bannerMessage')}
                popUpTitle={t('popUpTitle')}
        />
    </div>
  );  
}

export default App;
