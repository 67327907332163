import React, { useState, useEffect} from 'react';
import Select from 'react-select';
import "../CommonComponentsAndStyling/Filter.css";
import { getDistrictsHavingMTOs, getVillagesHavingMTOs } from "../../api";
import MTOs from './MTOs';

export default function FilterMTOs(props) {  
    const { usedLanguage } = props;

    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState([{ value: null , label: props.placeHolderName1 }])
    const [selectedRegionId, setSelectedRegionId] = useState(null);
  
    const [villages, setVillages] = useState([]);
    const [selectedVillageId, setSelectedVillageId] = useState(null);
    const [selectedVillage, setSelectedVillage] = useState([{ value: null , label: props.placeHolderName2 }]);

    const [showMTOs, setShowMTOs] = useState(false);
    
    const handleChangeRegion = (selectedRegion) => {
      if (selectedRegion !== null) {
        setSelectedRegion(selectedRegion);
        setSelectedRegionId(selectedRegion.value);
      }
    };
  
    const handleChangeVillage = (selectedVillage,) => {
      if (selectedVillage !== null) {
        setSelectedVillage(selectedVillage);
        setSelectedVillageId(selectedVillage.value);
      } 
    };
  
  const getTranslation = (translations, locale) => {
    const translation = translations.find(translation => translation.locale === locale);
      return translation ? translation.name : '';
  };
  
  useEffect(() => {

    setSelectedRegion(null);
    setSelectedVillage(null);

    const fetchDistrictData = async () => {
      try {
        const districtData = await getDistrictsHavingMTOs();
  
        const Districts = districtData.map(
          district => ({ value: district.code, label: getTranslation(district.translations, usedLanguage) })
        );
        
        setRegions([{ value: null , label: props.placeHolderName1 }, ...Districts]);
        
        setShowMTOs(true);
        
        const queryParams = new URLSearchParams(window.location.search);
    
        if(queryParams.size > 0) {
            let regionId = queryParams.get('region');
    
            let region = Districts.filter((reg) => {
              return regionId === reg.value;
            });
      
            if(region[0]) {
              handleChangeRegion(region[0]);
            }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchDistrictData();

  }, [usedLanguage, props.placeHolderName1]);
  
  useEffect(() => {

    let ignore = false;

    const fetchVillageData = async () => {
      try {
        if (selectedRegionId !== null) {
          const villageData = await getVillagesHavingMTOs(selectedRegionId);

          const Villages = villageData.map(
            village => ({ value: village.pcode, label: getTranslation(village.translations, usedLanguage) }));
            if(!ignore)
            {
              setVillages([{ value: null , label: props.placeHolderName2 }, ...Villages]);
            }
            
            if(!ignore)
            {
              setSelectedVillageId(null);
              setSelectedVillage({ value: null , label: props.placeHolderName2 });
            }
        } else {
          if(!ignore)
          {
            setSelectedVillageId(null);
            setSelectedVillage({ value: null , label: props.placeHolderName2 });
            setVillages([]);
          }
        }
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchVillageData();

    return () => {
      ignore = true;
    };
  }, [selectedRegionId, usedLanguage, props.placeHolderName2]);
  
  return (
    <div className='container'>
      <div className='inputDiv'>
        <Select
          className='input'
          value={selectedRegion}
          onChange={handleChangeRegion}
          options={regions}
          isSearchable={true}
          placeholder= {props.placeHolderName1}
          menuPortalTarget={document.body}
        />
        <Select
          className='input'
          value={selectedVillage}
          onChange={handleChangeVillage}
          options={villages}
          isSearchable={true}
          placeholder={props.placeHolderName2}
          menuPortalTarget={document.body}
        />
      </div>
      { showMTOs && <MTOs districtCode={selectedRegionId} 
            villagePcode={selectedVillageId} 
            mtosStatment={props.mtosStatment} 
            usedLanguage={usedLanguage}
            dataLoad={props.dataLoad}
            loading={props.loading}
            location={props.location}
        />  }
      </div>
    
    );
  };