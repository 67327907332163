import React from "react";

const Banner = ({ message, style, direction = "ltr" }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "32%", 
        left: direction === "ltr" ? "calc(92% - 110px)" : undefined, 
        right: direction === "rtl" ? "calc(92% - 110px)" : undefined, 
        width: "220px",
        transform: direction === "ltr" ? "rotate(90deg)" : "rotate(-90deg)", 
        backgroundColor: "red",
        color: "white",
        padding: "6px 60px", 
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "center",
        zIndex: 2, 
        ...(direction === "ltr"
          ? { right: "auto" } 
          : { left: "auto" }), 
        ...style, 
      }}
    >
      {message}
    </div>
  );
};

export default Banner;
