import React, { useEffect, useState } from "react";
import "../CommonComponentsAndStyling/Display.css";
import locationIcon from "../../images/location.png"
import { getAllShops, getShopByDistrict, getShopByVillage, getBaseUrl } from "../../api";
import InfiniteScroll from 'react-infinite-scroll-component';
import Banner from "../CommonComponentsAndStyling/Banner";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function Shops(props) {
  const { usedLanguage } = props;
  const [shops, setShops] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalShops, setTotalShops] = useState(0); 
  const [districtCode, setDistrictCode] = useState(null);
  const isRTL = document.documentElement.dir === "rtl";

  const fetchData = async (reset) => {
    try {
      
      let page = 1;

      if(!reset) {
        page = currentPage;
      }

      let data = [];

      let shopsList = [];

      if(props.districtCode && !props.villagePcode) {
        shopsList = await getShopByDistrict(props.districtCode, page);
      } else if(props.villagePcode) {
        shopsList = await getShopByVillage(props.villagePcode, page);
      } else {
        shopsList = await getAllShops(page);
      }

      if(shopsList.last_page > page)  {
        setCurrentPage(page + 1);
      } else {
        setHasMoreData(false);
      }

      if(reset) {
        data = shopsList.data;
      } else {
        data = [...shops, ...shopsList.data];
      }

      setTotalShops(shopsList.total);
      setShops(data);
      setDistrictCode(props.districtCode);
      
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);

    fetchData(true);
  }, [props.districtCode, props.villagePcode]);

  function getTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  function getPromotion(promotions, locale, field) {
    const promotion = promotions.find(t => t.locale === locale);
    return promotion ? promotion[field] : '';
  }

  const lengthStatment =`${props.shopsStatment} ${totalShops}`;
  
  return (

    
    <div className="container">      
    <div className="container-sub">
      <h6 className='num'>{lengthStatment}</h6>
      <hr className="line" />
      <InfiniteScroll
        dataLength={shops.length}
        next={fetchData}
        hasMore={hasMoreData}
        loader={<p className="load" >{props.loading}</p>}
        endMessage={<p className="load" >{props.dataLoad}</p>}
        >
      <Row className="rowStyle">
            {shops.map((shop, index) => (
              <div className="col-md-5">
                <Card key={index} className="divStyle">
                  <Card.Header className="cardHeader">
                    <Row className="no-gutters align-items-center">
                      <Col  xs={3} className="p-0">
                        <img src={getBaseUrl() + "storage/" + shop.image_path} className="img" alt="shop"/>
                      </Col>
                      <Col xs={8} className="p-0">
                        <Card.Title className="name">{getTranslation(shop.translations, usedLanguage, 'name')}</Card.Title>
                        <Card.Text className="shopId">{shop.wfp_shop_id}</Card.Text>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                      <Card.Text className="details">{getTranslation(shop.district.translations, usedLanguage, 'name')} - {getTranslation(shop.village.translations, usedLanguage, 'name')}</Card.Text>
                      <Card.Text className="details">{getTranslation(shop.translations, usedLanguage, 'address')}</Card.Text>
                  </Card.Body>
                  {shop.promotions && shop.promotions.length != 0 && (
                      <Card.Body className="promotionDiv">
                        <h7 className="popUpTitle">{props.popUpTitle}</h7>
                        {shop.promotions.map((promotion, promoIndex) => (
                          <Col key={promoIndex} className="promotionTxt">
                            <Card.Text>
                              {getPromotion(promotion.translations, usedLanguage, "promotion")}
                            </Card.Text>
                          </Col>
                        ))}
                        {shop.promotions && shop.promotions.length != 0 && 
                          <Banner
                            message={props.bannerMessage}
                            direction={isRTL ? "rtl" : "ltr"}
                          />
                        }
                      </Card.Body>
                  )}
                  <Card.Footer className="cardFooter">
                    <Row className="no-gutters align-items-center">
                      <a  href={`https://www.google.com/maps/search/?api=1&query=${shop.latitude},${shop.longitude}`} target="_blank">
                        <button className="locationButton">
                            <img src={locationIcon} alt="Location" className="buttonIcon"/>
                            <div className="buttonText">
                              {props.location}
                            </div>
                          </button>
                      </a>
                    </Row>
                  </Card.Footer>
                </Card>
                </div>
              ))} 
      </Row>
      </InfiniteScroll>
    </div>
    </div>
  );
}
