import React, { useEffect, useState } from "react";
import "../CommonComponentsAndStyling/Display.css";
import locationIcon from "../../images/location.png"
import { getAllSites, getSitesByDistrict, getSitesByVillage, getBaseUrl } from "../../api";
import InfiniteScroll from 'react-infinite-scroll-component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function Sites(props) {
  
  const {usedLanguage} = props;
  const [sites, setSites] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSites, setTotalSites] = useState(0);
  
  const fetchData = async (reset) => {
    try {
      
      let page = 1;

      if(!reset) {
        page = currentPage;
      }

      let data = [];

      let sitesList = [];

      if(props.districtCode && !props.villagePcode) {
        sitesList = await getSitesByDistrict(props.districtCode, page);
      } else if(props.villagePcode) {
        sitesList = await getSitesByVillage(props.villagePcode, page);
      } else {
        sitesList = await getAllSites(page);
      }

      if(sitesList.last_page > page)  {
        setCurrentPage(page + 1);
      } else {
        setHasMoreData(false);
      }

      if(reset) {
        data = sitesList.data;
      } else {
        data = [...sites, ...sitesList.data];
      }

      setTotalSites(sitesList.total);
      setSites(data);
      
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    setHasMoreData(true);
    setCurrentPage(1);

    fetchData(true);
  }, [props.districtCode, props.villagePcode]);

  function getTranslation(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  function getTranslationNoLocaleObj(translations, locale, field) {
    const translation = translations.find(t => t.locale === locale);
    return translation ? translation[field] : '';
  }

  const lengthStatement = `${props.sitesStatement} ${totalSites}`;

  return (
    <div className="container">
      <div className="container-sub">
        <h6 className='num'>{lengthStatement}</h6>
        <hr className="line" />
        <InfiniteScroll
          dataLength={sites.length}
          next={fetchData}
          hasMore={hasMoreData}
          loader={<p className="load" >{props.loading}</p>}
          endMessage={<p className="load" >{props.dataLoad}</p>}
        >
          <Row className="rowStyle">
            {sites.map((site, index) => (
              <div className="col-md-5">
                <Card key={index} className="divStyle">
                  <Card.Header className="cardHeader">
                    <Row className="no-gutters align-items-center">
                      <Col  xs={3} className="p-0">
                        <img src={getBaseUrl() + "storage/default-shop-img.png"} className="img" alt="site"/>
                      </Col>
                      <Col xs={8} className="p-0">
                        <Card.Title className="name">{getTranslation(site.translations, usedLanguage, 'name')}</Card.Title>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                      <Card.Text className="details">
                        {getTranslation(site.district.translations, usedLanguage, 'name')},
                        {getTranslation(site.village.translations, usedLanguage, 'name')},
                        {getTranslation(site.translations, usedLanguage, 'address')},
                        {getTranslation(site.translations, usedLanguage, 'building_name')},
                        {getTranslation(site.translations, usedLanguage, 'extra_details')}
                      </Card.Text>
                      <Card.Text className="details">{getTranslation(site.translations, usedLanguage, 'opening_days')}</Card.Text>
                      <Card.Text className="details">{getTranslation(site.translations, usedLanguage, 'opening_time')}</Card.Text>
                      <Card.Text className="details">{site.phone_number}</Card.Text>
                  </Card.Body>
                  <Card.Footer className="cardFooter">
                    <Row className="no-gutters align-items-center">
                      <a href={`https://www.google.com/maps/search/?api=1&query=${site.latitude},${site.longitude}`} target="_blank">
                        <button className="locationButton">
                          <img src={locationIcon} alt="Location" className="buttonIcon"/>
                          <div className="buttonText">
                            {props.location}
                          </div>
                        </button>
                      </a>
                    </Row>
                  </Card.Footer>
                </Card>
                </div>
              ))} 
      </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
}
