import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate   } from "react-router-dom";
import logo from "../images/wfp-logo-standard-white-en.png";
import "./Navbar.css";
import i18next from "i18next";
import FilterShops from "./Shops/FilterShops";
import FilterMTOs from "./MTOs/FilterMTOs";
import FilterEssnMTOs from "./EssnMTOs/FilterEssnMTOs";
import FilterSites from "./Sites/FilterSites"; 
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';

export default function NavBar(props) {
    const location = useLocation();
    
    let initialPath = '';

    if(location.pathname.startsWith('/shops')) {
        initialPath = 'shops';
    }

    if(location.pathname.startsWith('/mtos')) {
        initialPath = 'mtos';
    }

    if(location.pathname.startsWith('/essnMtos')) {
        initialPath = 'essnMtos';
    }

    if(location.pathname.startsWith('/sites')) {
        initialPath = 'sites';
    }

    const [activeTab, setActiveTab] = useState(initialPath);
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setShowOffcanvas(false);
    };

    const getHeaderText = () => {
        if (activeTab === "shops") {
          return props.shopHeaderName;
        }
        if (activeTab === "mtos") {
          return props.mtoHeaderName;
        }
        if (activeTab === "essnMtos") {
            return props.essnMtoHaderName;
        }
        if (activeTab === "sites") {
            return props.sitesHeaderName;
        }
      };

    return (
    <div > 
        <Navbar className="bg-body-tertiary mb-3" expand={false} style={{ direction: props.usedLanguage === 'ar' ? 'rtl' : 'ltr' }}>
            <Container fluid className="navBarContainer">
                <Navbar.Brand className="navBarSmall">
                    <img alt="" src={logo} className="logo"/>
                </Navbar.Brand>
                <div className="sideDiv">
                    <Navbar.Toggle 
                                aria-controls={'offcanvasNavbar-expand-false'} 
                                className="custom-navbar-toggler"
                                onClick={() => setShowOffcanvas(true)}
                    />
                    <ul className="translationList">
                        {Object.keys(props.lngs).map((lng) => (
                            <li  key={lng}>
                                <span 
                                    className="translation" 
                                    key={lng} onClick={() => props.changeLanguage(lng)} 
                                    disabled={i18next.resolvedLanguage === lng}
                                >
                                {props.lngs[lng].nativeName}
                                </span>
                            </li>
                        ))}
                    </ul>                         
                </div>
                <Navbar.Offcanvas
                    id={'offcanvasNavbar-expand-false'}
                    aria-labelledby={'offcanvasNavbarLabel-expand-false'}
                    placement={props.usedLanguage === 'ar' ? 'end' : 'start'}
                    show={showOffcanvas}
                    onHide={() => setShowOffcanvas(false)}
                    style={{ backgroundColor: "#0B77C2" }}
                >
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Link  to="/mtos" className = "leftButton" 
                            onClick={() => handleTabChange("mtos")}
                            style={{
                                backgroundColor: activeTab === "mtos" ? '#34A2D9' : '',
                            }}
                        >{props.pageName2}
                        </Link>
                        <Link  to="/shops" className = "leftButton" 
                            onClick={() => handleTabChange("shops")}
                            style={{
                                backgroundColor: activeTab === "shops" ? '#34A2D9' : '',
                            }}
                        >{props.pageName1}
                        </Link>
                        <Link  to="/essnMtos" className = "leftButton" 
                            onClick={() => handleTabChange("essnMtos")}
                            style={{
                                backgroundColor: activeTab === "essnMtos" ? '#34A2D9' : '',
                            }}
                        >{props.pageName3}
                        </Link>
                        <Link  to="/sites" className = "leftButton" 
                            onClick={() => handleTabChange("sites")}
                            style={{
                                backgroundColor: activeTab === "sites" ? '#34A2D9' : '',
                            }}
                        >{props.pageName4}
                        </Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
            <h2 className="headerName">{getHeaderText()}</h2>
            <Routes>
                <Route path="/" element={<Navigate  to="/mtos" />} />
                <Route path="/mtos" element={<FilterMTOs 
                                                placeHolderName1={props.placeHolderName1} 
                                                placeHolderName2={props.placeHolderName2}
                                                mtosStatment={props.mtosStatment}
                                                alertMessage={props.alertMessage}
                                                usedLanguage={props.usedLanguage}
                                                dataLoad={props.dataLoad}
                                                loading={props.loading}
                                                location={props.location}
                                            />} />
                <Route path="/shops" element={<FilterShops 
                                                placeHolderName1={props.placeHolderName1} 
                                                placeHolderName2={props.placeHolderName2}
                                                shopsStatment={props.shopsStatment}
                                                alertMessage={props.alertMessage}
                                                usedLanguage={props.usedLanguage}
                                                dataLoad={props.dataLoad}
                                                loading={props.loading}
                                                location={props.location}
                                                bannerMessage={props.bannerMessage}
                                                popUpTitle={props.popUpTitle}
                                            />} />
                <Route path="/essnMtos" element={<FilterEssnMTOs 
                                                placeHolderName1={props.placeHolderName1} 
                                                placeHolderName2={props.placeHolderName2}
                                                essnMtosStatment={props.essnMtosStatment}
                                                alertMessage={props.alertMessage}
                                                usedLanguage={props.usedLanguage}
                                                dataLoad={props.dataLoad}
                                                loading={props.loading}
                                                location={props.location}
                                            />} />
                <Route path="/sites" element={<FilterSites 
                                                placeHolderName1={props.placeHolderName1} 
                                                placeHolderName2={props.placeHolderName2}
                                                sitesStatement={props.sitesStatement}
                                                alertMessage={props.alertMessage}
                                                usedLanguage={props.usedLanguage}
                                                dataLoad={props.dataLoad}
                                                loading={props.loading}
                                                location={props.location}
                                            />} />
            </Routes>
    </div>
    )
}